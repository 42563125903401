import { Offcanvas } from 'bootstrap'

// Declare our namspace
window.MBlazor = window.MBlazor || {};
var MBlazor = window.MBlazor;
MBlazor.offcanvas = MBlazor.offcanvas || {};

/**
 * Update the range slider.
 * @param {String} inputSelector Input selector
 * @param {String} sliderSelector Slider selector
 */
MBlazor.offcanvas =
{
    initialize: (elementId, useStaticBackdrop, closeOnEscape, isScrollable, dotNetHelper) => {
        let offcanvasEl = document.getElementById(elementId);
        if (offcanvasEl == null)
            return;

        offcanvasEl.addEventListener('show.bs.offcanvas', function () {
            dotNetHelper.invokeMethodAsync('bsShowOffcanvas');
        });
        offcanvasEl.addEventListener('shown.bs.offcanvas', function () {
            dotNetHelper.invokeMethodAsync('bsShownOffcanvas');
        });
        offcanvasEl.addEventListener('hide.bs.offcanvas', function () {
            dotNetHelper.invokeMethodAsync('bsHideOffcanvas');
        });
        offcanvasEl.addEventListener('hidden.bs.offcanvas', function () {
            dotNetHelper.invokeMethodAsync('bsHiddenOffcanvas');
        });

        let options = { backdrop: useStaticBackdrop ? 'static' : true, keyboard: closeOnEscape, scroll: isScrollable };
        Offcanvas?.getOrCreateInstance(offcanvasEl, options);
    },
    show: (elementId) => {
        let offcanvasEl = document.getElementById(elementId);
        if (offcanvasEl != null)
            Offcanvas?.getOrCreateInstance(offcanvasEl)?.show();
    },
    hide: (elementId) => {
        let offcanvasEl = document.getElementById(elementId);
        if (offcanvasEl != null)
            Offcanvas?.getOrCreateInstance(offcanvasEl)?.hide();
    },
    dispose: (elementId) => {
        let offcanvasEl = document.getElementById(elementId);
        if (offcanvasEl != null)
            Offcanvas?.getOrCreateInstance(offcanvasEl)?.dispose();
    }
}