import { Toast } from 'bootstrap'

// Declare our namspace
window.MBlazor = window.MBlazor || {};
var MBlazor = window.MBlazor;
MBlazor.toasts = MBlazor.toasts || {};

/**
 * Update the range slider.
 * @param {String} inputSelector Input selector
 * @param {String} sliderSelector Slider selector
 */
MBlazor.toasts =
{
    show: (elementId, autohide, delay, dotNetHelper) => {
        let toastEl = document.getElementById(elementId);
        if (toastEl == null)
            return;

        toastEl.addEventListener('show.bs.toast', function () {
            dotNetHelper.invokeMethodAsync('bsShowToast');
        });
        toastEl.addEventListener('shown.bs.toast', function () {
            dotNetHelper.invokeMethodAsync('bsShownToast');
        });
        toastEl.addEventListener('hide.bs.toast', function () {
            let _this = this;
            if (_this == null || document.getElementById(_this.id) == null) // when a user is redirected to a different page, the HTML element becomes unavailable.
                return;
            dotNetHelper.invokeMethodAsync('bsHideToast');
        });
        toastEl.addEventListener('hidden.bs.toast', function () {
            let _this = this;
            if (_this == null || document.getElementById(_this.id) == null) // when a user is redirected to a different page, the HTML element becomes unavailable.
                return;
            dotNetHelper.invokeMethodAsync('bsHiddenToast');
        });

        let options = { animation: true, autohide: autohide, delay: delay };
        Toast?.getOrCreateInstance(toastEl, options)?.show();
    },
    hide: (elementId) => {
        let toastEl = document.getElementById(elementId);
        if (toastEl != null)
            Toast?.getOrCreateInstance(toastEl)?.hide();
    },
    dispose: (elementId) => {
        let toastEl = document.getElementById(elementId);
        if (toastEl != null)
            Toast?.getOrCreateInstance(toastEl)?.dispose();
    }
}